.results-row {
  margin-right: -8px;
  margin-left: -8px;
  margin-bottom: 1rem;
}
.results-row .results-card.ant-col {
  display: flex;
  align-items: stretch;
  padding: 0px 8px;
  margin-bottom: 16px;
}
