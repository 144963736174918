.header-segment {
  background-color: var(--loop54-header-color);
  color: var(--loop54-headerText-color);
  height: 123px;
  box-shadow: 0 -4px 40px rgba(0, 0, 0, 0.1);
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  max-height: 123px;
  transition: max-height 0.2s ease-in-out, width 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
  border-bottom: 1px solid transparent;
}
@media screen and (max-width: 768px) {
  .header-segment {
    height: auto;
    padding-bottom: 1rem;
    max-height: unset;
    position: relative;
    margin-bottom: 1rem;
  }
}
.header-segment .header-top-container {
  display: flex;
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  align-items: center;
  padding: 0px 15px;
  height: 77px;
}
@media screen and (max-width: 768px) {
  .header-segment .header-top-container {
    flex-direction: column;
    align-items: start;
    height: 100%;
  }
}
.header-segment.header-segment-no-categories .header-top-container {
  height: 100%;
}
.header-segment .header-bottom-container {
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  padding: 0px 15px;
  animation: fadeIn;
  animation-fill-mode: forwards;
  animation-duration: 0.8s;
}
@media screen and (max-width: 768px) {
  .header-segment .header-bottom-container {
    position: absolute;
    top: 15px;
    right: 15px;
    padding: 0px;
  }
}
.header-segment.header-segment-no-categories .header-segment-no-categories {
  display: none;
}
.diagnostic-mode-active .header-segment .header-top-container {
  max-width: 100%;
}
.diagnostic-mode-active .header-segment .header-bottom-container {
  max-width: 100%;
}
.header-segment .header-searchfield {
  display: none;
  margin-left: 1rem;
  flex: 1;
  animation: fadeIn;
  animation-duration: 0.2s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}
.header-segment .header-searchfield .searchfield-container {
  margin-top: 0px;
}
.header-segment .header-searchfield .main-search .addon-before {
  display: none;
}
.header-segment .header-searchfield .main-search .addon-after {
  border-bottom-right-radius: 20px;
  border-top-right-radius: 20px;
  border: 1px solid var(--loop54-border-color);
  padding: 0px 7px 0px 5px;
}
.header-segment .header-searchfield .main-search .addon-after .ant-btn-icon-only > * {
  font-size: 14px;
}
.header-segment .header-searchfield .main-search .searchfield-autocomplete.ant-select .ant-select-selection-search .ant-input {
  border: 1px solid var(--loop54-border-color);
  border-right: none;
  background-color: #fff;
  height: 35px;
  border-bottom-left-radius: 20px !important;
  border-top-left-radius: 20px !important;
  padding-left: 15px;
  font-size: 14px;
}
.header-segment .header-nav {
  margin-left: auto;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 768px) {
  .header-segment .header-nav {
    display: flex;
    align-items: center;
    margin-left: initial;
    width: 100%;
  }
}
@media screen and (max-width: 768px) {
  .header-segment .header-nav .ant-btn.ant-btn-text span:last-child {
    display: none;
  }
}
@media screen and (max-width: 768px) {
  .header-segment .header-nav .ant-btn.shopping-basket {
    margin-left: auto;
  }
}
.header-segment .header-title-container {
  position: relative;
  overflow: hidden;
  width: 100%;
}
.header-segment .header-title-container .custom-header-image span {
  width: 165px;
  height: 60px;
  background-size: contain;
  background-position: left center;
  background-repeat: no-repeat;
  display: block;
  max-height: 60px;
  max-width: 165px;
  transition: max-height 0.1s linear, max-width 0.1s linear;
}
.header-segment .header-title-container .header-text a, .header-segment .header-title-container .header-text a:hover {
  color: var(--loop54-headerTitle-color);
  text-transform: capitalize;
  font-size: 1.7rem;
  font-weight: 600;
  transition: font-size 0.1s linear;
}
.header-segment .header-title-container .header-text {
  margin-bottom: 0px;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}
.header-segment .header-title-container .demo-made-by {
  position: relative;
  top: -10px;
  font-size: 0.8rem;
}
.header-segment .ant-btn-text:hover, .header-segment .ant-btn-text:active, .header-segment .ant-btn-text:focus {
  background: inherit;
}
.header-segment .ant-btn-text {
  color: var(--loop54-headerText-color);
}
.header-segment .ant-btn-text:hover {
  color: var(--loop54-headerText-color);
}
@media screen and (min-width: 768px) {
  .header-segment.shrink-header {
    max-height: 55px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.01);
  }
  .header-segment.shrink-header .header-top-container {
    height: 100%;
  }
  .header-segment.shrink-header .header-bottom-container {
    display: none;
  }
  .header-segment.shrink-header .header-title-container {
    max-width: 280px;
  }
  .header-segment.shrink-header .header-title-container .custom-header-image span {
    max-height: 40px;
    max-width: 130px;
  }
  .header-segment.shrink-header .header-title-container .header-text a, .header-segment.shrink-header .header-title-container .header-text a:hover {
    font-size: 1.2rem;
  }
  .header-segment.shrink-header .header-title-container .demo-made-by {
    display: none;
  }
  .header-segment.shrink-header .header-searchfield {
    display: block;
  }
}

.header-search-container {
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  padding: 0 15px;
}
.diagnostic-mode-active .header-search-container {
  max-width: 100%;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}
