.search-top-col {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.search-top-col h2 {
  margin-bottom: 0px;
  margin-right: auto;
  font-weight: 400;
}
@media screen and (max-width: 768px) {
  .search-top-col h2 {
    margin-bottom: 5px;
  }
}
.search-top-col .show-filters-button {
  margin-right: 0.5rem;
  height: 32px;
}
@media screen and (max-width: 768px) {
  .search-top-col {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .search-top-col .sortby-dropdown {
    float: right;
  }
}
.search-top-col .search-trem {
  font-weight: 800;
  text-transform: capitalize;
}

.search-related-results {
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 1rem;
}
.search-related-results h2 {
  margin-bottom: 1rem;
}

.search-direct-results {
  padding-right: 8px;
  padding-left: 8px;
}

.LMR .search-direct-results:not(.no-related), .TLR .search-direct-results:not(.no-related) {
  padding-right: 24px;
}
@media screen and (max-width: 992px) {
  .LMR .search-direct-results:not(.no-related), .TLR .search-direct-results:not(.no-related) {
    padding-right: 8px;
  }
}

.LMR .show-filters-button, .LRB .show-filters-button {
  display: none;
}
@media screen and (max-width: 992px) {
  .LMR .show-filters-button, .LRB .show-filters-button {
    display: inline-block;
  }
}

.TLR .search-related-results, .LMR .search-related-results {
  position: relative;
}
.TLR .search-related-results:before, .LMR .search-related-results:before {
  content: " ";
  display: block;
  height: calc(100% - 81px);
  position: absolute;
  width: 1px;
  background-color: rgba(0, 0, 0, 0.06);
  left: -9px;
  top: 50px;
}

.search-row {
  margin-right: -8px;
  margin-left: -8px;
}
