.checkout-row {
  margin-right: -4px;
  margin-left: -8px;
  margin-bottom: 4rem;
  margin-top: 1rem;
}
.checkout-row .ant-col {
  padding: 0px 4px;
}
.checkout-row .ant-col .ant-card {
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(32, 34, 35, 0.04);
  border: 0px;
}
.checkout-row .checkout-summary-col.ant-col {
  margin-top: 2.2rem;
  padding-left: 16px;
}
.checkout-row .checkout-summary-col.ant-col .checkout-summary-sticky-section {
  position: sticky;
  top: 65px;
}
.checkout-row .checkout-summary-col.ant-col .checkout-summary-card .checkout-summary-total {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
}
.checkout-row .checkout-summary-col.ant-col .checkout-summary-card .checkout-summary-total span {
  font-size: 1.3rem;
  font-weight: 500;
}
.checkout-row .checkout-summary-col.ant-col .checkout-summary-card .checkout-summary-detailed {
  display: flex;
  align-items: baseline;
  justify-content: space-between;
  margin-top: 1rem;
}
.checkout-row .checkout-summary-col.ant-col .checkout-summary-card .checkout-summary-detailed span {
  font-size: 1rem;
  font-weight: 300;
}
.checkout-row .checkout-summary-col.ant-col .ant-btn.ant-btn-primary {
  margin-top: 1rem;
  background: var(--loop54-headerCartBackground-color);
  border-color: var(--loop54-headerCartBackground-color);
  color: var(--loop54-headerCart-color);
}
.checkout-row .checkout-summary-col.ant-col .ant-btn.ant-btn-primary:hover {
  background-color: var(--loop54-headerCartBackground-color);
  border-color: var(--loop54-headerCartBackground-color);
}
.checkout-row .checkout-summary-col.ant-col .ant-btn.ant-btn-primary[disabled] {
  opacity: 0.5;
}

.checkout-basket-item {
  margin-bottom: 1rem;
}
.checkout-basket-item .ant-card-body {
  display: flex;
  position: relative;
}
.checkout-basket-item .ant-card-body .product-image {
  width: 120px;
  text-align: center;
  margin-right: 1rem;
}
.checkout-basket-item .ant-card-body .product-image img {
  width: auto;
  height: 100%;
  max-height: 120px;
  max-width: 120px;
}
.checkout-basket-item .ant-card-body .product-info {
  display: flex;
  align-items: flex-start;
  flex-basis: 100%;
}
.checkout-basket-item .ant-card-body .product-info .product-title-container {
  flex: 1 0 40%;
  display: flex;
  flex-direction: column;
  padding-right: 1rem;
  cursor: pointer;
}
.checkout-basket-item .ant-card-body .product-info .product-title-container .title {
  font-size: 1.2rem;
  line-height: 1.1;
}
.checkout-basket-item .ant-card-body .product-info .product-title-container .brand {
  font-size: 0.8rem;
  font-weight: 300;
  margin-top: 0.2rem;
}
.checkout-basket-item .ant-card-body .product-info .amount {
  font-size: 1.1rem;
  font-weight: 300;
  flex: 0 0 20%;
}
.checkout-basket-item .ant-card-body .product-info .price {
  font-size: 1.1rem;
  font-weight: 300;
  flex: 0 0 20%;
}
.checkout-basket-item .ant-card-body .product-info .total-price {
  font-size: 1.2rem;
  flex: 0 0 20%;
  text-align: right;
}
.checkout-basket-item .ant-card-body .product-info .total-price .col-header {
  text-align: right;
  font-weight: 600;
}
.checkout-basket-item .ant-card-body .product-info .col-header {
  position: absolute;
  top: -30px;
  font-size: 1rem;
  font-weight: 400;
  display: none;
}
.checkout-basket-item .ant-card-body .delete-product-btn {
  position: absolute;
  bottom: 24px;
  right: 24px;
}
.checkout-basket-item .ant-card-body .ant-input-number {
  box-shadow: none !important;
  border-color: var(--loop54-border-color);
}
.checkout-basket-item .ant-card-body .ant-input-number:hover {
  border-color: var(--loop54-primary-color);
}
.checkout-basket-item:first-child .ant-card-body .product-info .col-header {
  display: initial;
}
.checkout-basket-item:last-child {
  margin-bottom: 0;
}

.basket-header {
  display: flex;
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
}
