.result-list-header {
  display: flex;
  align-items: center;
  margin-bottom: 1rem;
}
.result-list-header .ant-breadcrumb {
  font-size: 1.2rem;
  font-weight: 500;
  color: var(--loop54-text-color);
  margin-right: auto;
}
@media screen and (max-width: 768px) {
  .result-list-header .ant-breadcrumb {
    margin-bottom: 0.5rem;
  }
}
.result-list-header .ant-breadcrumb a {
  color: var(--loop54-text-color);
  opacity: 0.8;
  transition: color 0.3s, opacity 0.3s;
}
.result-list-header .ant-breadcrumb a:hover {
  color: var(--loop54-primary-color);
  opacity: 1;
}
.result-list-header .ant-breadcrumb > span:last-child {
  color: var(--loop54-text-color);
  opacity: 1;
}
.result-list-header .show-filters-button {
  margin-right: 0.5rem;
  height: 32px;
}
@media screen and (max-width: 768px) {
  .result-list-header {
    display: block;
  }
}
@media screen and (max-width: 768px) {
  .result-list-header .sortby-dropdown {
    float: right;
  }
}

.LMR .result-list-header .show-filters-button, .LRB .result-list-header .show-filters-button {
  display: none;
}
@media screen and (max-width: 992px) {
  .LMR .result-list-header .show-filters-button, .LRB .result-list-header .show-filters-button {
    display: inline-block;
  }
}

.category-results-col {
  padding-right: 8px;
  padding-left: 8px;
}

.category-row {
  margin-left: -8px;
  margin-right: -8px;
}
