.mobile-categories-dd-button {
  background: var(--loop54-headerCartBackground-color);
  border-color: var(--loop54-headerCartBackground-color);
  color: var(--loop54-headerCart-color);
}
.mobile-categories-dd-button:hover {
  background-color: var(--loop54-headerCartBackground-color);
  border-color: var(--loop54-headerCartBackground-color);
}
@media screen and (min-width: 992px) {
  .mobile-categories-dd-button {
    display: none;
  }
}

.category-listnings .category-listnings-items-col .ant-btn-text:hover, .category-listnings .category-listnings-items-col .ant-btn-text:active, .category-listnings .category-listnings-items-col .ant-btn-text:focus {
  background: inherit;
}
.category-listnings .category-listnings-items-col .ant-btn {
  font-weight: 500;
  padding: 0px;
  color: var(--loop54-headerText-color);
  margin-right: 1.5rem;
  text-align: left;
  position: relative;
}
.category-listnings .category-listnings-items-col .ant-btn:after {
  content: " ";
  position: absolute;
  bottom: -7px;
  left: 0;
  right: 100%;
  height: 2px;
  opacity: 0;
  background: var(--loop54-primary-color);
  transition: all 0.2s ease-out;
  transition-property: left, right;
  pointer-events: none;
}
.category-listnings .category-listnings-items-col .ant-btn[disabled] {
  cursor: pointer;
}
.category-listnings .category-listnings-items-col .ant-btn:last-child {
  margin-right: 0;
}
.category-listnings .category-listnings-items-col .ant-btn:hover {
  color: var(--loop54-primary-color);
}
.category-listnings .category-listnings-items-col .ant-btn.ant-dropdown-open {
  color: var(--loop54-primary-color);
}
.category-listnings .category-listnings-items-col .ant-btn.ant-dropdown-open:after {
  right: 0;
  opacity: 1;
}
.category-listnings .ant-divider {
  margin: 10px 0;
}
.category-listnings .category-listnings-main {
  width: 100%;
  display: flex;
  align-items: center;
}
@media screen and (max-width: 992px) {
  .category-listnings {
    display: none;
  }
}

.cat-dropdown-menu-container {
  width: 100%;
  position: relative;
}
.cat-dropdown-menu-container:after {
  content: " ";
}
.cat-dropdown-menu-container .ant-dropdown {
  top: 6px !important;
  left: 0 !important;
  right: 0 !important;
  width: 100%;
  overflow-y: auto;
  max-height: 65vh;
  box-shadow: 0px 15px 15px -10px rgba(0, 0, 0, 0.2);
  border-bottom: 2px solid var(--loop54-primary-color);
  color: var(--loop54-text-color);
}
.cat-dropdown-menu-container .ant-dropdown:before {
  display: none;
}
.cat-dropdown-menu-container .ant-dropdown .ant-dropdown-menu:not(.brand-dropdown) {
  box-shadow: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  columns: 3 200px;
  padding: 30px;
  padding-top: 20px;
}
.cat-dropdown-menu-container .ant-dropdown .ant-dropdown-menu:not(.brand-dropdown) .ant-dropdown-menu-item {
  break-inside: avoid;
  font-size: 1rem;
  padding: 0;
  color: var(--loop54-text-color);
}
.cat-dropdown-menu-container .ant-dropdown .ant-dropdown-menu:not(.brand-dropdown) .ant-dropdown-menu-item a {
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
  color: var(--loop54-text-color);
  margin: 0px -12px;
  padding: 8px 18px;
}
.cat-dropdown-menu-container .ant-dropdown .ant-dropdown-menu:not(.brand-dropdown) .ant-dropdown-menu-item .category-dropdown-title {
  font-size: 1.5rem;
  font-weight: 800;
}
.cat-dropdown-menu-container .ant-dropdown .ant-dropdown-menu:not(.brand-dropdown) .ant-dropdown-menu-item .category-dropdown-view-all {
  font-weight: 500;
}
.cat-dropdown-menu-container .brand-dropdown {
  box-shadow: none;
  border-top-right-radius: 0px;
  border-top-left-radius: 0px;
  padding: 30px;
  padding-top: 20px;
}
.cat-dropdown-menu-container .brand-dropdown .brand-index-header {
  width: 100%;
  display: flex;
  align-items: center;
  border-bottom: 1px solid var(--loop54-border-color);
  padding-bottom: 1rem;
  margin-bottom: 1rem;
  position: sticky;
  top: 0;
  background-color: #fff;
  padding-top: 1rem;
}
.cat-dropdown-menu-container .brand-dropdown .brand-index-header span {
  cursor: pointer;
  font-size: 1.5rem;
  font-weight: 500;
  text-transform: capitalize;
  flex: 1;
}
.cat-dropdown-menu-container .brand-dropdown h3.brand-heading {
  color: var(--loop54-text-color);
  font-size: 2rem;
  font-weight: 800;
  text-transform: capitalize;
  margin-bottom: -5px;
  padding-left: 10px;
}
.cat-dropdown-menu-container .brand-dropdown .brand-group {
  column-count: 3;
  margin-bottom: 2rem;
}
.cat-dropdown-menu-container .brand-dropdown .brand-group span {
  font-weight: 800;
  cursor: pointer;
}
.cat-dropdown-menu-container .brand-dropdown .brand-group a, .cat-dropdown-menu-container .brand-dropdown .brand-group span {
  padding: 10px 0px 10px 0px;
  color: var(--loop54-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  width: 100%;
  display: block;
  font-size: 1rem;
  padding: 10px 0px 10px 10px;
  break-inside: avoid;
  transition: all 0.3s;
}
.cat-dropdown-menu-container .brand-dropdown .brand-group a:hover, .cat-dropdown-menu-container .brand-dropdown .brand-group span:hover {
  background-color: #f5f5f5;
}

.category-drawer .ant-drawer-body {
  padding: 0px;
}
.category-drawer .ant-drawer-body::-webkit-scrollbar {
  width: 7px;
}
.category-drawer .ant-drawer-body::-webkit-scrollbar-track {
  background: #ddd;
}
.category-drawer .ant-drawer-body::-webkit-scrollbar-thumb {
  background: var(--loop54-scrollbar-color);
  border-radius: 0px;
}
.category-drawer .ant-drawer-title {
  color: var(--loop54-text-color);
}
.category-drawer .ant-menu .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .category-drawer .ant-menu .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: var(--loop54-primary-color);
}
.category-drawer .ant-menu .ant-menu-submenu-open, .category-drawer .ant-menu .ant-menu-item:hover, .category-drawer .ant-menu .ant-menu-item-active, .category-drawer .ant-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .category-drawer .ant-menu .ant-menu-submenu-active, .category-drawer .ant-menu .ant-menu-submenu-title:hover {
  color: var(--loop54-primary-color);
}
.category-drawer .ant-menu .ant-menu-item:active, .category-drawer .ant-menu .ant-menu-submenu-title:active {
  background: #fff;
}
.category-drawer .ant-menu .ant-menu-item-selected {
  color: var(--loop54-text-color);
}
.category-drawer .ant-menu .ant-menu-item-selected:after {
  display: none;
}

.menu-multi-level-dropdown {
  background-color: #fff;
}
.menu-multi-level-dropdown ul {
  line-height: initial;
  padding: 30px;
  padding-top: 20px;
}
.menu-multi-level-dropdown ul li {
  margin-bottom: 1.5rem;
}
.menu-multi-level-dropdown ul li.no-subcategories {
  margin-bottom: 1rem;
}
.menu-multi-level-dropdown ul li.no-subcategories:last-child {
  margin-bottom: 0;
}
.menu-multi-level-dropdown ul li a {
  color: var(--loop54-text-color);
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 100%;
}
.menu-multi-level-dropdown ul li .menu-multi-level-dropdown-heading {
  font-size: 1.5rem;
  font-weight: 800;
  padding-left: 10px;
  margin-bottom: 0.5rem;
  display: block;
}
.menu-multi-level-dropdown ul li.no-subcategories .menu-multi-level-dropdown-heading {
  margin-bottom: 0rem;
  font-size: 1.2rem;
}
.menu-multi-level-dropdown ul li .menu-multi-level-dropdown-subitems {
  column-count: 3;
}
.menu-multi-level-dropdown ul li .menu-multi-level-dropdown-subitems a {
  width: 100%;
  display: block;
  font-size: 1rem;
  padding: 10px 0px 10px 10px;
  break-inside: avoid;
  transition: all 0.3s;
}
.menu-multi-level-dropdown ul li .menu-multi-level-dropdown-subitems a:hover {
  background-color: #f5f5f5;
}
.menu-multi-level-dropdown ul li .menu-multi-level-dropdown-subitems .category-dropdown-view-all {
  font-weight: 500;
}

.category-listnings .ant-btn .text-dot, .category-listnings .ant-btn .text-dot:after, .category-listnings .ant-btn .text-dot:before {
  width: 4px;
  height: 4px;
  display: inline-block;
  background-color: var(--loop54-headerText-color);
  border-radius: 50%;
  position: relative;
  margin-right: 7px;
  margin-left: 7px;
  transition: all 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
}
.category-listnings .ant-btn .text-dot:before {
  content: " ";
  position: absolute;
  left: -6px;
  margin: 0;
}
.category-listnings .ant-btn .text-dot:after {
  content: " ";
  position: absolute;
  right: -6px;
  margin: 0;
}
.category-listnings .ant-btn.ant-dropdown-open .text-dot, .category-listnings .ant-btn.ant-dropdown-open .text-dot:before, .category-listnings .ant-btn.ant-dropdown-open .text-dot:after, .category-listnings .ant-btn:hover .text-dot, .category-listnings .ant-btn:hover .text-dot:before, .category-listnings .ant-btn:hover .text-dot:after {
  background-color: var(--loop54-primary-color);
}
.category-listnings .ant-btn.ant-dropdown-open .text-dot:before, .category-listnings .ant-btn:hover .text-dot:before {
  background-color: var(--loop54-primary-color);
}
.category-listnings .ant-btn.ant-dropdown-open .text-dot:after, .category-listnings .ant-btn:hover .text-dot:after {
  background-color: var(--loop54-primary-color);
}
