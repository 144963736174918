.basket-total {
  margin-left: 10px;
}

.basket {
  display: flex;
  align-items: center;
  cursor: initial;
}
.basket .basket-image {
  margin-right: 15px;
  width: 50px;
  cursor: pointer;
  text-align: center;
}
.basket .basket-image img {
  width: auto;
  height: 100%;
  max-height: 50px;
  max-width: 50px;
}
.basket .basket-text {
  font-size: 15px;
  display: flex;
  flex-direction: column;
  cursor: pointer;
  margin-right: 1rem;
}
@media screen and (max-width: 768px) {
  .basket .basket-text {
    max-width: 70%;
  }
}
.basket .basket-text span {
  color: var(--loop54-text-color);
  font-weight: 500;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 400px;
}
.basket .basket-remove-item-btn {
  margin-left: auto;
  opacity: 0;
  transform: scale(0.5);
  transform-origin: center center;
  transition: all 0.3s ease-in;
}
.basket:hover .basket-remove-item-btn {
  opacity: 1;
  transform: scale(1);
}

.shopping-basket-dropdown {
  min-width: 500px !important;
}
@media screen and (max-width: 768px) {
  .shopping-basket-dropdown {
    max-width: 100vw;
    min-width: 100vw !important;
  }
}
.shopping-basket-dropdown .ant-dropdown-menu {
  padding: 0px 0;
  background-color: #fff;
  max-height: calc(100vh - 121px);
  overflow-y: auto;
  position: relative;
}
@media screen and (max-width: 768px) {
  .shopping-basket-dropdown .ant-dropdown-menu {
    max-height: 100%;
  }
}
.shopping-basket-dropdown .ant-dropdown-menu::-webkit-scrollbar {
  width: 5px;
}
.shopping-basket-dropdown .ant-dropdown-menu::-webkit-scrollbar-track {
  background: #ddd;
  border-radius: 5px;
}
.shopping-basket-dropdown .ant-dropdown-menu::-webkit-scrollbar-thumb {
  background: var(--loop54-scrollbar-color);
  border-radius: 5px;
}
.shopping-basket-dropdown .ant-dropdown-menu-item {
  padding: 10px 15px;
  border-top: 1px solid var(--loop54-border-color);
}
.shopping-basket-dropdown .ant-dropdown-menu-item:nth-child(2) {
  border-top: 0;
}
.shopping-basket-dropdown .ant-dropdown-menu-item .ant-btn-text {
  color: var(--loop54-text-color);
}
.shopping-basket-dropdown .basket-empty-cart-message {
  font-size: 1rem;
  font-weight: 500;
}
.shopping-basket-dropdown .basket-header {
  font-size: 1rem;
  font-weight: 500;
  padding: 1rem;
  display: flex;
  align-items: center;
  position: sticky;
  top: 0;
  background-color: #fff;
  z-index: 10;
  border-bottom: 1px solid var(--loop54-border-color);
}
.shopping-basket-dropdown .basket-header .ant-btn {
  margin-left: auto;
}
.shopping-basket-dropdown .basket-bottom-area {
  position: sticky;
  bottom: 0;
  z-index: 10;
  width: 100%;
  left: 0;
  height: auto;
  background-color: #fff;
  border-top: 1px solid var(--loop54-border-color);
}
.shopping-basket-dropdown .basket-bottom-area .basket-bottom-area-text {
  font-size: 1.1rem;
  display: block;
  margin-bottom: 0.5rem;
}
.shopping-basket-dropdown .basket-bottom-area .basket-purchase-area {
  padding: 1rem;
}
.shopping-basket-dropdown .basket-bottom-area .basket-purchase-area .ant-btn-primary {
  background: var(--loop54-headerCartBackground-color);
  border-color: var(--loop54-headerCartBackground-color);
  color: var(--loop54-headerCart-color);
}
.shopping-basket-dropdown .basket-bottom-area .basket-purchase-area .ant-btn-primary:hover {
  background-color: var(--loop54-headerCartBackground-color);
  border-color: var(--loop54-headerCartBackground-color);
}
.shopping-basket-dropdown .basket-bottom-area .basket-purchase-area .ant-btn-primary[disabled] {
  opacity: 0.5;
}
.shopping-basket-dropdown .basket-bottom-area .basket-purchase-area .goto-basket-page-link {
  display: block;
  text-align: center;
  margin-top: 5px;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area {
  padding: 1rem 1.7rem;
  border-top: 1px solid var(--loop54-border-color);
  background-color: #fff;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container {
  width: 500px;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-next {
  right: -18px;
  transition: opacity 0.2s linear;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-next.hidden {
  opacity: 0;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-prev {
  left: -18px;
  transition: opacity 0.2s linear;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-prev.hidden {
  opacity: 0;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-prev, .shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-next, .shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-prev, .shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-prev {
  color: #c4c4c4;
  font-size: 20px;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-prev::before, .shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-next::before, .shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-prev::before, .shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .ant-carousel .slick-prev::before {
  display: none;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .slide-item {
  padding: 0.5rem;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .slide-item .ant-card {
  border: 1px solid var(--loop54-border-color);
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .slide-item .product-description {
  display: none;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .slide-item .product-image-container {
  min-height: auto;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .slide-item .product-image-container img {
  width: 100%;
  height: auto;
  max-width: 100px;
  margin: 0 auto;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .slide-item .product-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  font-size: 0.9rem;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .slide-item .product-price {
  font-size: 1.3rem;
  margin-bottom: 0.2rem;
}
.shopping-basket-dropdown .basket-bottom-area .basket-rec-area .items-rec-container .slide-item .ant-card-body {
  height: 250px;
}

.shopping-basket.ant-btn-primary {
  background: var(--loop54-headerCartBackground-color);
  border-color: var(--loop54-headerCartBackground-color);
  color: var(--loop54-headerCart-color);
  box-shadow: none;
}
.shopping-basket.ant-btn-primary:hover {
  background-color: var(--loop54-headerCartBackground-color);
  border-color: var(--loop54-headerCartBackground-color);
}
.shopping-basket.ant-btn-primary .ant-badge {
  margin-left: 7px;
  position: relative;
  top: -1px;
}
.shopping-basket.ant-btn-primary .ant-badge .ant-badge-count {
  color: #4e4c4c;
  background: #ffffff;
  font-weight: 500;
  min-width: 18px;
  height: 18px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

.history-basket.ant-btn-primary {
  background-color: #fff;
  border-color: var(--loop54-border-color);
  box-shadow: none;
  margin-right: 15px;
  color: var(--loop54-text-color);
}
.history-basket.ant-btn-primary:hover {
  background-color: #fff;
  border-color: var(--loop54-border-color);
  box-shadow: none;
}
.history-basket.ant-btn-primary .ant-badge {
  margin-left: 7px;
  position: relative;
  top: -1px;
}
.history-basket.ant-btn-primary .ant-badge:empty {
  display: none;
}
.history-basket.ant-btn-primary .ant-badge .ant-badge-count {
  color: #4e4c4c;
  background: #ffffff;
  font-weight: 500;
  min-width: 18px;
  height: 18px;
  box-shadow: 0 0 0 1px rgba(0, 0, 0, 0.1);
}

#basket-dropdown-container, #basket-history-dropdown-container {
  position: relative;
}
#basket-dropdown-container .ant-dropdown, #basket-history-dropdown-container .ant-dropdown {
  top: 0px !important;
}
@media screen and (max-width: 768px) {
  #basket-dropdown-container .ant-dropdown, #basket-history-dropdown-container .ant-dropdown {
    top: 20px !important;
  }
}
