.loading-container {
  display: flex;
  align-items: center;
  height: 100vh;
  margin-top: -91px;
  justify-content: center;
}
.loading-container .ant-spin-text {
  color: var(--loop54-text-color);
  font-weight: 500;
  font-size: 1.2rem;
  margin-top: 1rem;
}
.loading-container .anticon {
  color: var(--loop54-primary-color);
}
