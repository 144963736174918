.TMB .facets-row, .TLR .facets-row {
  animation: fadeInDown;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
}

.facets-container {
  width: 100%;
}
.facets-container .facet-col {
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 1rem;
}
.facets-container .facets-button-container .ant-col {
  padding-right: 8px;
  padding-left: 8px;
}
.facets-container .clear-filter-col {
  padding-right: 8px;
  padding-left: 8px;
  margin-bottom: 0.5rem;
}
.facets-container .facets-active-col {
  padding-right: 8px;
  padding-left: 8px;
  display: flex;
  align-items: center;
}
.facets-container .facets-active-col .facets-active-title {
  font-size: 1rem;
  font-weight: 500;
  margin-right: 5px;
}
.facets-container .facets-active-col .ant-tag {
  border: none;
  background-color: #fff;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 3px 6px;
  color: var(--loop54-text-color);
}
.facets-container .facets-active-col .active-filters-clear-btn {
  margin-left: auto;
}
.facets-container .facets-active-list + .facets-row {
  margin-top: 0.5rem;
}
.facets-container .facets-active-list {
  margin-bottom: 0.5rem;
}

.facet-card.ant-card {
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(32, 34, 35, 0.04);
  height: 100%;
  max-height: 400px;
  overflow-y: auto;
}
.facet-card.ant-card::-webkit-scrollbar {
  width: 8px;
}
.facet-card.ant-card::-webkit-scrollbar-track {
  background: #ddd;
}
.facet-card.ant-card::-webkit-scrollbar-thumb {
  background: var(--loop54-scrollbar-color);
  border-radius: 5px;
}
.facet-card.ant-card .ant-card-body {
  padding: 4px 15px;
}
.facet-card.ant-card .ant-card-head-title {
  padding: 11px 0;
}
.facet-card.ant-card .show-more-button {
  margin-bottom: 1rem;
  margin-top: 1rem;
}
.facet-card.ant-card .ant-checkbox-checked::after {
  border: 1px solid var(--loop54-primary-color);
}
.facet-card.ant-card .ant-checkbox-checked .ant-checkbox-inner {
  background-color: var(--loop54-primary-color);
  border-color: var(--loop54-primary-color);
}
.facet-card.ant-card .ant-checkbox-checked .ant-checkbox-inner::after {
  border: 2px solid var(--loop54-primaryText-color);
  border-top: 0;
  border-left: 0;
}
.facet-card.ant-card .ant-checkbox-wrapper:hover .ant-checkbox-inner, .facet-card.ant-card .ant-checkbox:hover .ant-checkbox-inner, .facet-card.ant-card .ant-checkbox-input:focus + .ant-checkbox-inner {
  border-color: var(--loop54-primary-color);
}
.facet-card.ant-card .price-slider {
  margin-top: 1rem;
}
.facet-card.ant-card .price-slider .ant-slider-track {
  background-color: var(--loop54-primary-color);
}
.facet-card.ant-card .price-slider .ant-slider-dot-active {
  border-color: var(--loop54-primary-color);
}
.facet-card.ant-card .price-slider .ant-slider-handle {
  border-color: var(--loop54-primary-color);
}
.facet-card.ant-card .price-slider .ant-slider-mark {
  font-size: 11px;
}

@keyframes fadeInDown {
  0% {
    opacity: 0.5;
    -webkit-transform: translateY(-10px);
  }
  100% {
    opacity: 1;
    -webkit-transform: translateY(0);
  }
}
