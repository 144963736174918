.demo-footer {
  background-color: var(--loop54-bodyBg-color);
  max-width: 1170px;
  width: 100%;
  padding: 1rem 15px;
  margin-bottom: 2rem;
  margin-right: auto;
  margin-left: auto;
}
.diagnostic-mode-active .demo-footer {
  max-width: 100%;
}

.demo-footer-container {
  display: flex;
  align-items: center;
  width: 100%;
  border-top: 1px solid rgba(0, 0, 0, 0.06);
  padding-top: 1rem;
  font-size: 12px;
  color: var(--loop54-text-color);
}
.demo-footer-container svg {
  margin-right: 1rem;
}
.demo-footer-container svg #L54-typography {
  fill: var(--loop54-text-color);
}
.demo-footer-container .privacy-policy {
  margin-left: auto;
  margin-right: 1rem;
}
.demo-footer-container a {
  color: var(--loop54-text-color);
}
.demo-footer-container .social-icons .anticon {
  margin-right: 10px;
  color: var(--loop54-text-color);
}
.demo-footer-container .social-icons:last-child {
  margin-right: 0;
}
