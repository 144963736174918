.diagnostic-item-multiselect-actions-menu-container {
  position: fixed;
  bottom: 0;
  width: 100%;
  left: 0;
  right: 0;
  animation: fadeInDown;
  animation-duration: 0.3s;
  animation-fill-mode: forwards;
  animation-timing-function: linear;
  z-index: 1000;
}
.diagnostic-item-multiselect-actions-menu-container:after {
  content: " ";
  position: fixed;
  z-index: 999;
  background-color: #fff;
  background: linear-gradient(to bottom, rgba(255, 255, 255, 0) 0%, white 16%, white 18%);
  width: 100%;
  height: 150px;
  bottom: 0;
  left: 0;
}
.diagnostic-item-multiselect-actions-menu-container .diagnostic-item-multiselect-actions-menu {
  background-color: #42525f;
  border: 1px solid var(--loop54-border-color);
  padding: 0.5rem 1rem;
  box-shadow: 0 0 40px 0 rgba(8, 8, 8, 0.2);
  width: 800px;
  margin: 0 auto;
  position: relative;
  z-index: 1000;
  border-radius: 4px;
  display: flex;
  align-items: center;
  bottom: 40px;
}
.diagnostic-item-multiselect-actions-menu-container .diagnostic-item-multiselect-actions-menu .info-text {
  margin-right: auto;
  color: #ffF;
}
.diagnostic-item-multiselect-actions-menu-container .diagnostic-item-multiselect-actions-menu .action-container .ant-btn {
  margin-left: 0.5rem;
}
