.diagnostic-query-info-panel .diagnostic-query-card {
  border: none;
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(32, 34, 35, 0.04);
  height: 100%;
  max-height: 350px;
  overflow-y: auto;
  width: 100%;
}
.diagnostic-query-info-panel .diagnostic-query-card::-webkit-scrollbar {
  width: 8px;
}
.diagnostic-query-info-panel .diagnostic-query-card::-webkit-scrollbar-track {
  background: #ddd;
}
.diagnostic-query-info-panel .diagnostic-query-card::-webkit-scrollbar-thumb {
  background: var(--loop54-scrollbar-color);
  border-radius: 5px;
}
.diagnostic-query-info-panel .diagnostic-query-card .ant-card-body {
  padding: 4px 15px;
}
.diagnostic-query-info-panel .diagnostic-query-card .ant-card-head-title {
  padding: 11px 0;
}
.diagnostic-query-info-panel .diagnostic-query-card .diagnostic-query-list-item .prop-name {
  font-weight: 800;
}
.diagnostic-query-info-panel .diagnostic-query-card .diagnostic-query-list-item .prop-value {
  margin-left: 5px;
}
.diagnostic-query-info-panel .diagnostic-query-card .diagnostic-query-list-item .color-box {
  display: inline-block;
  padding: 5px;
}
.diagnostic-query-info-panel .ant-row {
  margin-right: -8px;
  margin-left: -8px;
  margin-bottom: 1rem;
}
.diagnostic-query-info-panel .ant-col {
  padding: 0px 8px;
  margin-bottom: 16px;
  display: -ms-flexbox;
  display: flex;
  align-items: stretch;
}
