.main-search {
  display: flex;
  align-items: stretch;
  margin-top: 5px;
}
.main-search .addon-before {
  border-top-right-radius: 0;
  border-bottom-right-radius: 0;
  border-top-left-radius: 5rem;
  border-bottom-left-radius: 5rem;
  background-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
  padding: 0px 15px 0px 22px;
  font-size: 16px;
  display: flex;
  align-items: center;
}
.main-search .addon-after {
  border-top-right-radius: 5rem;
  border-bottom-right-radius: 5rem;
  background-color: var(--loop54-darkGrey-color);
  padding: 0px 15px 0px 10px;
  color: var(--loop54-darkGreyText-color);
  display: flex;
  align-items: center;
}
.main-search .addon-after .ant-btn {
  color: var(--loop54-darkGreyText-color);
}
.main-search .addon-after .ant-btn-icon-only > * {
  font-size: 19px;
}
.main-search .searchfield-autocomplete.ant-select {
  flex: 1 1;
}
.main-search .searchfield-autocomplete.ant-select .ant-select-selection-search .ant-input {
  height: 50px;
}
.main-search .searchfield-autocomplete.ant-select .ant-select-selection-search .ant-input {
  border: none;
  font-size: 16px;
}
.main-search .searchfield-autocomplete.ant-select .ant-select-selection-search .ant-input:focus, .main-search .searchfield-autocomplete.ant-select .ant-select-selection-search .ant-input-focused {
  border-color: #d9d9d9;
  box-shadow: none;
}
.main-search .searchfield-autocomplete.ant-select .ant-select-selection-search .ant-input:hover {
  border-color: #d9d9d9;
}
.main-search .searchfield-autocomplete.ant-select .ant-select-selection-search .ant-input {
  border-left: 0px;
}
.main-search .searchfield-autocomplete.ant-select .ant-select-single .ant-select-selector .ant-select-selection-search {
  left: 0px;
}

.searchfield-autocomplete-dropdown .ant-select-item-option {
  padding: 0px;
}
.searchfield-autocomplete-dropdown .ant-select-item-option .autoselect-item {
  display: flex;
  align-items: center;
  font-size: 0.9rem;
  padding: 9px 12px;
}
.searchfield-autocomplete-dropdown .ant-select-item-option .autoselect-item .autoselect-item-label {
  text-transform: capitalize;
  margin-right: 3px;
}
.searchfield-autocomplete-dropdown .ant-select-item-option .autoselect-item .autoselect-item-facet .anticon {
  margin-right: 3px;
}
.searchfield-autocomplete-dropdown .ant-select-item-option .autoselect-item .autoselect-item-facet span {
  font-weight: bold;
}
.searchfield-autocomplete-dropdown .ant-select-item-option-selected:not(.ant-select-item-option-disabled) {
  background-color: #fff;
  font-weight: 400;
}
