.pages-container {
  margin-top: 1rem;
}
.pages-container .page-num {
  padding: 8px 11px;
  border: 1px solid #cacaca;
  margin-right: 0.5rem;
  color: var(--loop54-text-color);
}
.pages-container .page-num:hover {
  background: var(--loop54-primary-color);
  border-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}
.pages-container .page-num.dots:hover {
  background: none;
}
.pages-container .page-num.page-current {
  font-weight: bold;
  background: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}
.pages-container .page-num.page-current:hover {
  background: var(--loop54-primary-color);
  border-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}

.pagination-container {
  margin-bottom: 1rem;
  width: 100%;
  text-align: center;
}
.pagination-container .pagination-progress {
  margin-bottom: 0.5rem;
}
.pagination-container .pagination-progress p {
  margin-bottom: 0px;
  position: relative;
  bottom: -3px;
}
.pagination-container .pagination-progress .ant-progress-line {
  max-width: 250px;
}
.pagination-container .pagination-progress .ant-progress-line .ant-progress-inner {
  background-color: rgba(0, 0, 0, 0.2);
}
.pagination-container .pagination-progress .ant-progress-line .ant-progress-inner .ant-progress-bg {
  background-color: var(--loop54-text-color);
}
.pagination-container .ant-btn {
  width: 100%;
  max-width: 300px;
}
.pagination-container .ant-btn-primary {
  background: var(--loop54-primary-color);
  border-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
  box-shadow: none;
}
.pagination-container .ant-btn-primary:hover {
  background-color: var(--loop54-primary-color);
  border-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}
