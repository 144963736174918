.diagnostic-visualizer-drawer .ant-drawer-body {
  padding: 0;
}
.diagnostic-visualizer-drawer .ant-drawer-body iframe {
  width: 100%;
  height: 100%;
  border: none;
}
.diagnostic-visualizer-drawer .ant-drawer-body .ant-row {
  height: 100%;
}
.diagnostic-visualizer-drawer .ant-drawer-body .visualizer-items {
  height: 100%;
  overflow: auto;
  padding: 15px;
  border-left: 1px solid var(--loop54-border-color);
  box-shadow: inset 0 0 10px rgba(0, 0, 0, 0.1);
  background-color: var(--loop54-bodyBg-color);
  padding-bottom: 15px;
}
.diagnostic-visualizer-drawer .ant-drawer-body .visualizer-items .results-card {
  margin-top: 15px;
  pointer-events: none;
}
.diagnostic-visualizer-drawer .ant-drawer-body .visualizer-items .results-card .ant-card.product-selected {
  box-shadow: 0 0 6px rgba(2, 3, 3, 0.04);
  background-color: #fff;
}
.diagnostic-visualizer-drawer .ant-drawer-body .visualizer-items .results-card .product-context-menu-btn {
  display: none;
}
.diagnostic-visualizer-drawer .ant-drawer-body .visualizer-items .results-card:first-child {
  margin-top: 0px;
}
.diagnostic-visualizer-drawer .ant-drawer-body .visualizer-items .results-card:last-child .ant-card {
  margin-bottom: 15px;
}
