.single-product-card {
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(32, 34, 35, 0.04);
  border: 0px;
  margin-bottom: 2rem;
}
.single-product-card .ant-card-body {
  padding: 75px 30px;
}
.single-product-card .single-product-image-col {
  display: flex;
  align-items: center;
  justify-content: center;
}
.single-product-card .single-product-image-col .ant-image-mask:hover {
  background: rgba(66, 82, 95, 0.7);
}
.single-product-card .single-product-image-col .ant-image {
  max-width: 300px;
  text-align: center;
}
@media screen and (max-width: 992px) {
  .single-product-card .single-product-image-col .ant-image {
    margin-bottom: 1rem;
  }
}
.single-product-card .single-product-image-col .ant-image-img {
  width: auto;
  height: 100%;
  max-height: 300px;
  max-width: 300px;
}
@media screen and (max-width: 992px) {
  .single-product-card .single-product-image-col .ant-image-img {
    max-width: 100%;
  }
}
.single-product-card .single-product-details-col {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  flex-direction: column;
  padding-left: 0.5rem;
}
@media screen and (max-width: 992px) {
  .single-product-card .single-product-details-col {
    padding-left: 0rem;
  }
}
.single-product-card .single-product-details-col .single-product-sale-tag {
  position: absolute;
  top: -40px;
  left: 10px;
  background-color: var(--loop54-red-color);
  padding: 5px 15px;
  color: #fff;
}
.single-product-card .single-product-details-col .single-product-new-tag {
  position: absolute;
  top: -40px;
  right: 10px;
  background-color: var(--loop54-primary-color);
  padding: 5px 15px;
  color: #fff;
}
.single-product-card .single-product-details-col .single-product-description {
  font-size: 1.1rem;
  word-break: break-word;
}
.single-product-card .single-product-details-col .single-product-price {
  font-size: 2.5rem;
  margin-bottom: 0.5rem;
}
.single-product-card .single-product-details-col .single-product-price .item-price-sale-original {
  opacity: 0.7;
}
.single-product-card .single-product-details-col .single-product-price .product-sales-price {
  color: var(--loop54-red-color);
  margin-left: 0.5rem;
}
.single-product-card .single-product-details-col .button-container .single-product-add-cart-button.ant-btn {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
  width: 50px;
}
.single-product-card .single-product-details-col .button-container .ant-btn.ant-btn-primary {
  background-color: var(--loop54-addToCart-color);
  border-color: var(--loop54-addToCart-color);
  color: var(--loop54-addToCartText-color);
  box-shadow: none;
}
.single-product-card .single-product-details-col .button-container .ant-btn.ant-btn-primary:hover {
  background-color: var(--loop54-addToCart-color);
  border-color: var(--loop54-addToCart-color);
  color: var(--loop54-addToCartText-color);
}
.single-product-card .single-product-details-col .button-container .ant-btn {
  width: 150px;
}
@media screen and (max-width: 992px) {
  .single-product-card .single-product-details-col .button-container .ant-btn {
    width: initial;
  }
}
.single-product-card .single-product-details-col .single-product-brand {
  font-size: 1.3rem;
  font-weight: 400;
  opacity: 0.7;
}
@media screen and (max-width: 992px) {
  .single-product-card .single-product-details-col .single-product-brand {
    font-size: 1.1rem;
  }
}
.single-product-card .single-product-details-col h1 {
  font-size: 2rem;
  color: var(--loop54-text-color);
  margin-bottom: 0.5rem;
  line-height: 1.2;
}
@media screen and (max-width: 992px) {
  .single-product-card .single-product-details-col h1 {
    font-size: 1.7rem;
  }
}
.single-product-card .single-product-attributes-col {
  margin-top: 2rem;
}
