.related-queries {
  margin-top: 1rem;
  padding-bottom: 1rem;
}
.related-queries .related-queries-title {
  font-size: 1rem;
  font-weight: 500;
  display: inline-block;
  margin-right: 5px;
}
.related-queries .related-queries-list {
  display: inline-block;
}
.related-queries .related-queries-list .ant-tag {
  border: none;
  background-color: #fff;
  font-size: 0.8rem;
  font-weight: 400;
  padding: 3px 6px;
  color: var(--loop54-text-color);
  cursor: pointer;
  text-transform: capitalize;
}
.related-queries .related-queries-list .ant-tag:hover {
  background-color: var(--loop54-primary-color);
  color: #fff;
}
