.no-page {
  height: 100vh;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-wrap: wrap;
  flex-direction: column;
  text-align: center;
  background-color: #fff;
}
.no-page .no-page-message-logo {
  position: fixed;
  top: 25px;
  left: 25px;
  width: 170px;
  height: 45px;
  background-image: url(./images/logo.svg);
  background-size: contain;
  background-repeat: no-repeat;
}
.no-page .ant-layout-footer {
  margin-top: auto;
  background-color: #fff;
}
.no-page .no-page-message-wrapper {
  margin-top: auto;
  position: relative;
  overflow: visible;
  width: 100%;
}
.no-page .no-page-message-wrapper .no-page-message-modal {
  background-color: #fff;
  padding: 65px 50px;
  box-shadow: 0px 0px 30px rgba(0, 0, 0, 0.1);
  max-width: 700px;
  margin: 0 auto;
  position: relative;
  z-index: 5;
  border-radius: 4px;
}
@media screen and (max-width: 768px) {
  .no-page .no-page-message-wrapper .no-page-message-modal {
    padding: 25px 25px;
    margin-right: 15px;
    margin-left: 15px;
  }
}
.no-page .no-page-message-wrapper .no-page-message-modal h1 {
  font-size: 1.8rem;
}
.no-page .no-page-message-wrapper .no-page-message-modal h1.error-code {
  font-size: 3rem;
  margin-bottom: 0;
}
.no-page .no-page-message-wrapper .no-page-message-modal p {
  font-size: 1.4rem;
  font-weight: 300;
  margin-bottom: 0px;
}
.no-page .no-page-message-wrapper .no-page-message-modal .protocol-error {
  display: block;
  margin-top: 10px;
}
.no-page .no-page-message-wrapper:after {
  background-image: url(./images/triangles.svg);
  content: "";
  position: absolute;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  z-index: 1;
  pointer-events: none;
  width: 100%;
  max-width: 1000px;
  height: 100vh;
  right: 0;
  left: 0;
  margin: 0 auto;
  margin-top: -60vh;
  background-position: center center;
}
@media screen and (max-width: 768px) {
  .no-page .no-page-message-wrapper:after {
    display: none;
  }
}
