.welcome-message {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-top: 1rem;
  margin-bottom: 2rem;
}
.welcome-message img {
  width: 100%;
  height: auto;
  max-width: 330px;
  margin-bottom: 50px;
  margin-top: 50px;
}
.welcome-message h1 {
  font-size: 2.5rem;
  margin-bottom: 0rem;
  color: #000073;
}
@media screen and (max-width: 992px) {
  .welcome-message h1 {
    font-size: 1.5rem;
    text-align: center;
  }
}
.welcome-message p {
  font-size: 1.5rem;
  text-align: center;
  font-weight: 300;
  max-width: 80%;
}
@media screen and (max-width: 992px) {
  .welcome-message p {
    font-size: 1.1rem;
    max-width: 100%;
  }
}
