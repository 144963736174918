.diagnostic-toolbar {
  position: fixed;
  bottom: 0;
  left: 0;
  background-color: #42525f;
  color: #fff;
  z-index: 10;
  width: 100%;
  height: auto;
  max-height: 123px;
  transition: max-height 0.2s linear;
}
.diagnostic-toolbar:hover {
  max-height: 600px;
}
.diagnostic-toolbar .diagnostic-toolbar-top {
  height: 30px;
  width: 100%;
  left: 0;
  right: 0;
  display: flex;
  align-items: stretch;
  background-color: #333;
}
.diagnostic-toolbar .diagnostic-toolbar-top .toolbar-top-item {
  height: 30px;
  line-height: 30px;
  padding-right: 10px;
  padding-left: 10px;
  text-shadow: 0px 0px 2px #333;
}
.diagnostic-toolbar .diagnostic-toolbar-top .toolbar-top-item span {
  font-weight: 600;
}
.diagnostic-toolbar .diagnostic-toolbar-top .toolbar-top-item.diagnostic-active {
  background-color: #ff715a;
  flex: 1;
}
.diagnostic-toolbar .diagnostic-toolbar-top .toolbar-top-item.user-active {
  background-color: #4fbbd0;
  flex: 1;
}
.diagnostic-toolbar .diagnostic-toolbar-top .toolbar-top-item.oeu-active {
  background-color: #FF984D;
  flex: 1;
}
.diagnostic-toolbar .diagnostic-toolbar-top .toolbar-top-item.user-intent-active {
  background-color: #9b7bdb;
}
.diagnostic-toolbar .diagnostic-user-info {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
}
.diagnostic-toolbar .diagnostic-user-info .user-id span {
  font-weight: 600;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences {
  width: 100%;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-title {
  font-weight: 600;
  padding: 5px 10px 0px 10px;
  display: inline-block;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-list {
  width: 100%;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-list .preference-container {
  display: flex;
  width: 100%;
  margin-top: 5x;
  border-top: 1px solid #3c4852;
  padding: 5px 10px;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-list .preference-container:first-child {
  border: none;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-list .preference-container .pref-container-name {
  font-weight: 400;
  margin-right: 0.5rem;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-list .preference-container .pref-container-items {
  display: flex;
  justify-content: center;
  align-items: center;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-list .preference-container .pref-container-items .ant-tag.pref-container-item {
  font-size: 0.7rem;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-list .preference-container .pref-container-items .ant-tag.pref-container-item span:first-child {
  font-weight: 700;
  margin-right: 0.2rem;
}
.diagnostic-toolbar .diagnostic-user-info .user-preferences .user-preferences-list .preference-container .pref-container-items .ant-tag.pref-container-item span:last-child {
  font-weight: 400;
}
.diagnostic-toolbar .diagnostic-user-info .user-intent {
  padding: 10px 1rem;
  margin-left: 1rem;
  width: 30%;
}
.diagnostic-toolbar .diagnostic-user-info .user-intent span {
  font-weight: 600;
}
.diagnostic-toolbar .diagnostic-user-info .user-intent .user-intent-list {
  display: flex;
  width: 100%;
}
.diagnostic-toolbar .diagnostic-user-info .user-intent .user-intent-list .ant-tag {
  font-size: 1rem;
  font-weight: 400;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  max-width: 330px;
}
