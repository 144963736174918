.drawer-settings-section {
  padding: 20px 12px;
  background-color: var(--loop54-bodyBg-color);
  color: var(--loop54-text-color);
  cursor: initial;
}
.drawer-settings-section h2 {
  margin-bottom: 10px;
}
.drawer-settings-section h2, .drawer-settings-section h3 {
  color: #42525f;
}
.drawer-settings-section label {
  display: block;
  margin-bottom: 0.3rem;
}
.drawer-settings-section .feature-switch {
  display: flex;
  align-items: center;
  margin-top: 1rem;
}
.drawer-settings-section .feature-switch:last-child {
  border: none;
  padding-bottom: 0px;
}
.drawer-settings-section .feature-switch .ant-switch.ant-switch-checked {
  background-color: var(--loop54-primary-color);
}
.drawer-settings-section .feature-switch .feature-switch-label {
  font-size: 0.9rem;
  margin-right: auto;
}
.drawer-settings-section .ant-input-group-addon .anticon {
  margin-right: 0px;
}
.drawer-settings-section .ant-input-group-addon {
  border-color: var(--loop54-border-color);
}
.drawer-settings-section .ant-input {
  border-color: var(--loop54-border-color);
}
.drawer-settings-section .ant-btn {
  color: #42525f;
}
.drawer-settings-section .ant-btn .anticon {
  margin-right: 0;
}
.drawer-settings-section .ant-btn-primary {
  background-color: var(--loop54-addToCart-color);
  border-color: var(--loop54-addToCart-color);
  color: var(--loop54-addToCartText-color);
  box-shadow: none;
}
.drawer-settings-section .ant-btn-group {
  width: 100%;
}
.drawer-settings-section .ant-btn-group .ant-btn {
  flex: 100%;
}
.drawer-settings-section .ant-btn:not(.ant-btn-primary):not(.ant-btn-text) {
  color: var(--loop54-text-color);
  background-color: #fff;
}
.drawer-settings-section .ant-btn:not(.ant-btn-primary):not(.ant-btn-text):hover, .drawer-settings-section .ant-btn:not(.ant-btn-primary):not(.ant-btn-text):focus {
  background-color: #fff;
  border-color: var(--loop54-primary-color);
}

.layout-select {
  margin-bottom: 1rem;
}
.layout-select img {
  width: 70px;
  margin-right: 5px;
  border: 1px solid var(--loop54-border-color);
  padding: 5px;
  cursor: pointer;
}
.layout-select img.active {
  border-color: #43A2B4;
  border-width: 2px;
}

.ant-tag {
  border: 1px solid var(--loop54-border-color);
}

.layout-info .ant-tag {
  padding: 0px 5px;
}
.layout-info .ant-tag img {
  margin-right: 3px;
  position: relative;
  top: -2px;
}

.setting-section {
  padding-bottom: 1rem;
}

.advanced-setting-section {
  margin-top: 1rem;
  padding-top: 1rem;
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}
.advanced-setting-section .input-error {
  color: var(--loop54-red-color);
  display: block;
  margin-top: 0.3rem;
}
.advanced-setting-section .btn-container {
  margin-top: 0.5rem;
}
.advanced-setting-section .btn-container .ant-btn {
  margin-right: 0.5rem;
}
.advanced-setting-section .custom-card-attribute-field {
  margin-top: 0.5rem;
  position: relative;
}
.advanced-setting-section .custom-card-attribute-field .ant-btn {
  position: absolute;
  top: 0;
  right: 0;
  opacity: 0;
  pointer-events: none;
  z-index: 5;
  height: 32px;
  transition: all 0.2s linear;
}
.advanced-setting-section .custom-card-attribute-field:hover .ant-btn {
  opacity: 1;
  pointer-events: initial;
}

.advanced-setting-custom-attributes-tooltip span {
  white-space: nowrap;
}

body.settings-drawer-active .ant-layout {
  margin-right: 343px;
}
body.settings-drawer-active .header-segment {
  width: calc(100% - 360px);
}
body.settings-drawer-active .ant-drawer.ant-drawer-open .ant-drawer-mask {
  background-color: rgba(111, 108, 108, 0.2);
}

.settings-drawer .ant-drawer-title {
  color: #42525f;
}
.settings-drawer .ant-drawer-header {
  padding: 16px 12px;
}
.settings-drawer .ant-drawer-body {
  padding: 0px;
}
.settings-drawer .ant-drawer-body::-webkit-scrollbar {
  width: 7px;
}
.settings-drawer .ant-drawer-body::-webkit-scrollbar-track {
  background: #ddd;
}
.settings-drawer .ant-drawer-body::-webkit-scrollbar-thumb {
  background: var(--loop54-scrollbar-color);
  border-radius: 0px;
}
.settings-drawer .ant-menu {
  margin-top: -4px;
}
.settings-drawer .ant-menu .ant-menu-item {
  padding-left: 0px !important;
  padding-right: 0px;
  height: auto;
  line-height: initial;
  overflow: visible;
}
.settings-drawer .ant-menu .ant-menu-submenu {
  border-bottom: 1px solid #f0f0f0;
}
.settings-drawer .ant-menu .ant-menu-submenu-title {
  margin-top: 0px;
  margin-bottom: 0px;
  padding-left: 12px !important;
  padding-right: 12px;
  height: 50px;
  line-height: 50px;
  user-select: none;
  color: var(--loop54-text-color);
}
.settings-drawer .ant-menu .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-expand-icon, .settings-drawer .ant-menu .ant-menu-submenu:hover > .ant-menu-submenu-title > .ant-menu-submenu-arrow {
  color: var(--loop54-text-color);
}
.settings-drawer .ant-menu .ant-menu-submenu-open, .settings-drawer .ant-menu .ant-menu-item:hover, .settings-drawer .ant-menu .ant-menu-item-active, .settings-drawer .ant-menu .ant-menu:not(.ant-menu-inline) .ant-menu-submenu-open, .settings-drawer .ant-menu .ant-menu-submenu-active, .settings-drawer .ant-menu .ant-menu-submenu-title:hover {
  color: var(--loop54-text-color);
}
.settings-drawer .ant-menu .ant-menu-inline .ant-menu-item {
  margin-top: 0px;
  margin-bottom: 0px;
}
.settings-drawer .ant-menu .ant-menu-item:active, .settings-drawer .ant-menu .ant-menu-submenu-title:active {
  background: #fff;
}
.settings-drawer .ant-menu .ant-menu-item-selected {
  color: var(--loop54-primary-color);
}
.settings-drawer .ant-menu .ant-menu-item-selected:after {
  display: none;
}
.settings-drawer .color-picker-dropdown {
  position: relative;
  margin-bottom: 1rem;
  display: flex;
  align-items: center;
}
.settings-drawer .color-picker-dropdown .ant-btn {
  position: relative;
  display: flex;
  align-items: center;
}
.settings-drawer .color-picker-dropdown .ant-btn .color-picker-icon {
  display: inline-block;
  width: 20px;
  height: 20px;
  margin-left: auto;
  border-radius: 50%;
  box-shadow: 0px 0px 2px #333;
}
.settings-drawer .color-picker-dropdown .reset-btn {
  height: 32px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #fff;
  width: 35px;
  align-items: center;
  margin-right: 0px;
  border: 1px solid;
  border-right: 0px;
  border-color: var(--loop54-border-color);
}
.settings-drawer .color-picker-dropdown .color-picker-dropdown-popover {
  position: absolute;
  z-index: 2;
  top: 42px;
  width: 100%;
}
.settings-drawer .color-picker-dropdown .block-picker {
  width: 100% !important;
  box-shadow: 0 -4px 40px rgba(128, 95, 95, 0.04) !important;
}
.settings-drawer .color-picker-dropdown .color-picker-dropdown-cover {
  position: fixed;
  top: 0px;
  right: 50px;
  bottom: 0px;
  left: 0px;
}
