:root {
  --loop54-text-color: #42525F;
  --loop54-primary-color: #000073;
  --loop54-primaryText-color: #ffffff;
  --loop54-addToCart-color: #DBC5F8;
  --loop54-addToCartText-color: #000073;
  --loop54-red-color: #FF5900;
  --loop54-yellow-color: #FFBF00;
  --loop54-darkGrey-color: #DBC5F8;
  --loop54-darkGreyText-color: #000073;
  --loop54-border-color: #efefff;
  --loop54-bodyBg-color: #f5f8ff;
  --loop54-header-color: #ffffff;
  --loop54-headerText-color: #42525f;
  --loop54-headerCartBackground-color: #4eff99;
  --loop54-headerCart-color: #000073;
  --loop54-headerTitle-color: #000073;
  --loop54-tooltip-color: #000073;
  --loop54-scrollbar-color: #42525f;
}

::-moz-selection {
  background-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}

::selection {
  background-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}

body {
  margin: 0;
  padding: 0;
  font-family: "Inter var", sans-serif !important;
  background-color: var(--loop54-bodyBg-color);
  color: var(--loop54-text-color);
}

h1, h2, h3, h4, h5, h6 {
  color: var(--loop54-text-color);
}

a {
  color: var(--loop54-primary-color);
}

a:hover {
  color: var(--loop54-primary-hover-color);
}

.ant-card {
  color: var(--loop54-text-color);
}

.ant-layout {
  background-color: var(--loop54-bodyBg-color);
  margin-bottom: 2rem;
  padding-top: calc(123px + 1rem);
  margin-right: 0;
  transition: margin-right 0.3s cubic-bezier(0.7, 0.3, 0.1, 1);
}
@media screen and (max-width: 768px) {
  .ant-layout {
    padding-top: 0;
  }
}
.diagnostic-mode-active .ant-layout {
  padding-bottom: 130px;
}

.ant-layout-content {
  max-width: 1170px;
  margin-right: auto;
  margin-left: auto;
  margin-top: 20px;
  width: 100%;
  padding: 0px 15px;
  opacity: 1;
  transition: opacity 0.3s ease;
}
.diagnostic-mode-active .ant-layout-content {
  max-width: 100%;
}

.ant-btn {
  font-family: "Inter var", sans-serif !important;
}

.ant-btn:not(.ant-btn-primary):not(.ant-btn-text) {
  border-color: var(--loop54-border-color);
  color: var(--loop54-text-color);
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-text):hover {
  border-color: var(--loop54-primary-color);
  background-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-text):focus {
  border-color: var(--loop54-primary-color);
  background-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}
.ant-btn:not(.ant-btn-primary):not(.ant-btn-text):active {
  border-color: var(--loop54-primary-color);
  background-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}

.ant-input:focus, .ant-input-focused {
  border-color: var(--loop54-border-color);
  box-shadow: none;
}

.ant-input:hover {
  border-color: var(--loop54-border-color);
  border-right-width: 1px !important;
}

.ant-input-search .ant-input:hover, .ant-input-search .ant-input:focus {
  border-color: var(--loop54-border-color);
}

.error-container, .offline-container {
  display: flex;
  align-items: center;
  height: 100vh;
  font-size: 2rem;
}

.ant-select:not(.ant-select-disabled):hover .ant-select-selector {
  border-color: var(--loop54-primary-color);
}

.ant-select-focused:not(.ant-select-disabled).ant-select:not(.ant-select-customize-input) .ant-select-selector {
  border-color: #d9d9d9;
  outline: 0;
  box-shadow: none;
}

@media screen and (max-width: 768px) {
  .ant-drawer-content-wrapper {
    width: 100vw !important;
  }
}

.ant-alert-warning {
  background-color: #f2d4be;
  border: 1px solid #FF984D;
}

.ant-alert-error {
  background-color: #f9ccc4;
  border: 1px solid #FF715A;
}

.ant-tooltip {
  font-size: 0.9rem;
  font-weight: 300;
  max-width: 300px;
}
.ant-tooltip .ant-tooltip-inner {
  background-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
  padding: 9px 15px;
  border-radius: 5px;
}
.ant-tooltip .ant-tooltip-arrow-content {
  width: 10px;
  height: 10px;
  background-color: var(--loop54-primary-color);
}
.ant-tooltip .ant-tooltip-arrow {
  width: 15px;
  height: 13px;
}

.ant-drawer-title {
  color: var(--loop54-text-color);
}

.override-active {
  position: fixed;
  bottom: 5px;
  left: 5px;
  padding: 5px 10px;
  font-size: 0.8rem;
  background-color: var(--loop54-red-color);
  color: #fff;
  z-index: 100;
}

.ant-back-top {
  right: 30px;
  bottom: 30px;
}
.ant-back-top .ant-btn.ant-btn-primary {
  border-color: var(--loop54-primary-color);
  background-color: var(--loop54-primary-color);
  color: var(--loop54-primaryText-color);
}

.body-category-dd-active .ant-layout-content, .body-category-dd-active .searchfield-container, .body-category-dd-active .demo-footer {
  opacity: 0.2;
}
