.diagnostic-compare-drawer .ant-drawer-body {
  padding: 0;
}
.diagnostic-compare-drawer .ant-drawer-body .ant-table .ant-table-tbody > tr.ant-table-row:hover > td {
  background: #e8e8e8;
}
.diagnostic-compare-drawer .ant-drawer-body .ant-table .ant-table-cell-fix-left {
  text-transform: capitalize;
}
.diagnostic-compare-drawer .ant-drawer-body .ant-table .table-row-issame {
  background-color: #ffe6d4 !important;
}
.diagnostic-compare-drawer .ant-drawer-body .ant-table .table-row-issame .ant-table-cell-fix-left {
  background-color: #ffe6d4 !important;
}
.diagnostic-compare-drawer .ant-drawer-body .ant-table .table-row-issame:hover > td {
  background-color: #ffe6d4 !important;
}
