.results-card .ant-card {
  border-radius: 4px;
  box-shadow: 0 0 6px rgba(32, 34, 35, 0.04);
  border: 0px;
  width: 100%;
}
.results-card .ant-card.product-selected {
  background-color: #f3f3f3;
  box-shadow: 0px 0px 0px 2px var(--loop54-primary-color);
}
.results-card .ant-card.product-contains-context {
  padding-top: 40px;
}
.results-card .ant-card .product-context-menu-btn {
  position: absolute;
  top: 5px;
  left: 5px;
  z-index: 4;
}
.results-card .ant-card.product-contains-context .product-context-menu-btn {
  top: -35px;
}
.results-card .ant-card .ant-card-body {
  padding: 15px;
  display: flex;
  flex-direction: column;
  flex: 1 1;
  flex-basis: auto;
  height: 100%;
  position: relative;
}
.results-card .ant-card .ant-card-body .product-sale-tag {
  position: absolute;
  top: 0;
  left: 0;
  background-color: var(--loop54-red-color);
  padding: 5px 15px;
  color: #fff;
  border-top-left-radius: 4px;
}
.results-card .ant-card .ant-card-body .product-new-tag {
  position: absolute;
  top: 0;
  right: 0;
  background-color: var(--loop54-primary-color);
  padding: 5px 15px;
  color: #fff;
  border-top-right-radius: 4px;
}
.results-card .ant-card .ant-card-body .context-info-tag {
  position: absolute;
  top: -40px;
  right: 0;
  left: 0;
  padding: 15px 10px;
  border-top-right-radius: 4px;
  border-top-left-radius: 4px;
  text-transform: uppercase;
  text-align: center;
  font-size: 0.7rem;
  overflow: hidden;
}
.results-card .ant-card .ant-card-body .context-info-tag span {
  position: relative;
  z-index: 2;
}
.results-card .ant-card .ant-card-body .context-info-tag .product-context-background {
  position: absolute;
  top: 0px;
  left: 0;
  width: 100%;
  z-index: 1;
  bottom: 0;
  opacity: 0.6;
}
.results-card .ant-card .ant-card-body .product-content-top {
  flex-basis: auto;
  flex: 1 1;
  margin-bottom: 0.5rem;
  position: relative;
  z-index: 2;
  color: var(--loop54-text-color);
}
.results-card .ant-card .ant-card-body .product-content-top .product-image-container {
  min-height: 160px;
  width: 65%;
  margin: 0 auto 0.8rem;
  display: block;
  flex: 1 1;
  text-align: center;
}
.results-card .ant-card .ant-card-body .product-content-top .product-image-container img {
  max-width: 100%;
  height: auto;
  max-height: 160px;
}
.results-card .ant-card .ant-card-body .product-content-top .product-id {
  font-weight: 300;
  opacity: 0.7;
  font-size: 0.8rem;
}
.results-card .ant-card .ant-card-body .product-content-top .product-id.ant-tooltip-open {
  font-size: 0.8rem;
}
.results-card .ant-card .ant-card-body .product-content-top .product-brand {
  font-weight: 300;
  opacity: 0.7;
  margin-bottom: 0.2rem;
}
.results-card .ant-card .ant-card-body .product-content-top .product-title {
  font-weight: 600;
  font-size: 1rem;
  text-decoration: none;
  line-height: 1;
}
.results-card .ant-card .ant-card-body .product-content-top .product-description {
  font-size: 0.8rem;
  font-weight: 400;
  margin-bottom: 0;
  margin-top: 0.2rem;
  word-break: break-word;
}
.results-card .ant-card .ant-card-body .product-content-top .product-custom-attributes .product-custom-attribute {
  margin-top: 0.5rem;
  padding-top: 0.5rem;
  border-top: 1px solid var(--loop54-border-color);
  line-height: 1;
  word-break: break-word;
}
.results-card .ant-card .ant-card-body .product-content-top .product-custom-attributes .product-custom-attribute .product-custom-attribute-name {
  opacity: 0.8;
  font-size: 10px;
}
.results-card .ant-card .ant-card-body .product-content-top .product-custom-attributes .product-custom-attribute .product-custom-attribute-value {
  font-size: 16px;
}
.results-card .ant-card .ant-card-body .product-content-bottom {
  position: relative;
  z-index: 2;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-price {
  font-size: 1.5rem;
  font-weight: 500;
  margin-bottom: 0.8rem;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-price .item-price-sale-original {
  opacity: 0.7;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-price .product-sales-price {
  color: var(--loop54-red-color);
  margin-left: 0.5rem;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-btn-container {
  display: flex;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-btn-container .ant-btn {
  background-color: var(--loop54-addToCart-color);
  border-color: var(--loop54-addToCart-color);
  color: var(--loop54-addToCartText-color);
  box-shadow: none;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-btn-container .ant-btn:hover {
  background-color: var(--loop54-addToCart-color);
  border-color: var(--loop54-addToCart-color);
  color: var(--loop54-addToCartText-color);
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-btn-container .product-purchase-btn {
  width: 100%;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-btn-container .product-purchase-btn .anticon {
  display: none;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-btn-container .product-add-cart-btn {
  margin-left: 0.5rem;
}
.results-card .ant-card .ant-card-body .product-content-bottom .product-btn-container .product-add-cart-btn:hover {
  border-right: 1px solid #36414b;
}
.diagnostic-mode-active .results-card .ant-card .product-description {
  display: none;
}
.diagnostic-mode-active .results-card .ant-card .product-content-top .product-image-container {
  height: 100px;
  min-height: 0;
}
.diagnostic-mode-active .results-card .ant-card .product-content-top .product-image-container img {
  max-height: 100px;
}
.diagnostic-mode-active .results-card .ant-card .product-content-top .product-brand {
  font-size: 0.7rem;
}
.diagnostic-mode-active .results-card .ant-card .product-content-top .product-title {
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  height: 2.7rem;
  overflow: hidden;
  word-break: break-word;
  font-size: 0.8rem;
  line-height: 1.7;
}
.diagnostic-mode-active .results-card .ant-card .product-content-bottom {
  display: flex;
  align-items: center;
}
.diagnostic-mode-active .results-card .ant-card .product-content-bottom .product-price {
  margin-bottom: 0;
  margin-right: 0.5rem;
  font-size: 0.8rem;
}
.diagnostic-mode-active .results-card .ant-card .product-content-bottom .product-btn-container {
  margin-left: auto;
}
.diagnostic-mode-active .results-card .ant-card .product-content-bottom .product-btn-container .ant-btn {
  width: 50px;
  height: 25px;
  margin-left: 2px;
}
.diagnostic-mode-active .results-card .ant-card .product-content-bottom .product-btn-container .ant-btn .anticon {
  display: block;
}
.diagnostic-mode-active .results-card .ant-card .product-content-bottom .product-btn-container .ant-btn .add-cart-text {
  display: none;
}
